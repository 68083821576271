<article class="entry" *ngFor="let item of data">
  <a [routerLink]="['/' +catSlug +'/',  item.slug]">
    <figure class="entry__image">
      <img class="img-responsive"
        src="{{item['better_featured_image']['media_details']['sizes']['content-lg'].source_url}}"
        alt="{{item['better_featured_image'].description ? item['better_featured_image'].description : 'Bilde'}}" />
    </figure>
    <header class="entry__header">
      <h2 class="entry__header-title h4">
        {{item.title.rendered}}
      </h2>
    </header>
  </a>
</article>
