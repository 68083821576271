import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeadroomModule } from '@ctrl/ngx-headroom';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MetaModule, MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { CoreRoutingModule } from './core-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';

export function metaFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' - ',
    applicationName: 'Rettentil.no',
    defaults: {
      title: 'Rettentil.no',
      description: 'For deg som møter unge utsatt for negativ sosial kontroll, tvangsekteskap og kjønnslemlestelse i ditt arbeid',
      'og:type': 'website',
      'og:locale': 'no',
    }
  });
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    HeadroomModule,
    BrowserAnimationsModule,
    DeviceDetectorModule.forRoot(),
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: (metaFactory)
    })
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
  ]
})
export class CoreModule { }
