import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DataService } from './../../../core/services/data.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isDevMode } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { Subscription } from 'rxjs';

import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  host: { '[class]': 'componentClass' }
})
export class HeroComponent implements OnInit, OnDestroy {
  componentClass: string;
  slug: string;
  paramSubscription: Subscription;

  heroDetail: string;
  hero: any;

  displayTitle: boolean = true;
  defaultHero: boolean;
  type: string;

  imageService: any;
  featuredImage: string;

  OGimage: string = null;
  OGwidth: string = null;
  OGheight: string = null;

  deviceInfo = null;
  mobileDevice = null;
  tabletDevice = null;
  desktopDevice = null;


  constructor(
    private _dataService: DataService,
    private readonly _meta: MetaService,
    private _deviceService: DeviceDetectorService,
  ) {}
  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.deviceFunction();
  }

  ngOnInit() {
    this.deviceFunction();
    this.paramSubscription = this._dataService._apiData$
    .subscribe((res) => {

      this.imageService = '';
      this.featuredImage = '';
      this.type = '';
      this.hero = [];


      if (res) {
        if (res[0].type) {
          this.type = res[0].type;
        }

        this.slug = res[0].slug === 'forside' ? 'home' : this.type;
        this.componentClass = 'hero hero__' +this.slug;


        if (this.type === 'post') {
          this.displayTitle = false;
        } else {
          this.displayTitle = true;
        }

        if ( res[0].better_featured_image) {
          this.imageService = res[0].better_featured_image.media_details.sizes;
          if ( this.imageService.hero ) {
            this.featuredImage = this.imageService.hero.source_url;
          } else {
            this.featuredImage = res[0].better_featured_image.source_url;
          }
          if ( this.mobileDevice ) {
            if ( this.imageService.featured_mobile ) {
              this.featuredImage = this.imageService.hero.source_url;
            }
          }
        }

        this.hero['title'] = res[0].title.rendered;
        this.hero['subtitle'] = false;
        if ( res[0].acf.ingress ) {
          this.hero['subtitle'] = res[0].acf.ingress.replace(/<[^>]*>/g, '');
        }

        if(isDevMode()) {
          console.log('hero, ' +JSON.stringify(this.hero));
          console.log('images, ' +JSON.stringify(this.imageService));
          console.log('image, ' +this.featuredImage);
          console.log('subTitle,' +this.hero['subTitle']);
          console.log('type, ' +this.type);
          console.log('title, ' +this.displayTitle);
          console.log('device, ' +JSON.stringify(this.deviceInfo));
          console.log('mobile, ' +this.mobileDevice);
          console.log('tablet, ' +this.tabletDevice);
          console.log('desktop, ' +this.desktopDevice);
          console.log('defaultHero, ' + this.defaultHero);
        }

        this.metaFunction(res);
      }
    });
  }

  ngOnDestroy(): void {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
    this.imageService = '';
    this.featuredImage = '';
    this.hero = [];
    this.defaultHero = false;
    this.type = '';
  }


  deviceFunction(): void {
    this.deviceInfo = this._deviceService.getDeviceInfo();
    this.mobileDevice = this._deviceService.isMobile();
    this.tabletDevice = this._deviceService.isTablet();
    this.desktopDevice = this._deviceService.isDesktop();
  }


  metaFunction(res): void {

    this._meta.setTitle(res[0].title.rendered);

    if ( res[0].better_featured_image) {
      this.imageService = res[0].better_featured_image.media_details.sizes;
    }

    if ( this.imageService ) {
      this._meta.setTag('og:image', environment.domain +this.featuredImage);
      // this._meta.setTag('og:image:width',  this.imageService.hero.width);
      // this._meta.setTag('og:image:height', this.imageService.hero.height);
    }

    if ( res[0].excerpt ) {
      this._meta.setTag('og:description', res[0].excerpt.rendered.replace(/<[^>]*>/g, ''));
    }

    if ( this.type === 'post' ) {
      this._meta.setTag('og:type', 'article');
    }
    else {
      this._meta.setTag('og:type', 'website');
    }



  }
}
