/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/components/archive/archive.component.ngfactory";
import * as i2 from "../../shared/components/archive/archive.component";
import * as i3 from "../../core/services/api.service";
import * as i4 from "@angular/common";
import * as i5 from "./home.component";
import * as i6 from "@angular/router";
import * as i7 from "../../core/services/data.service";
var styles_HomeComponent = [];
var RenderType_HomeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "entry__content ingress"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit["acf"].ingress; _ck(_v, 0, 0, currVal_0); }); }
function View_HomeComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "figure", [["class", "content__right module__background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["class", "img-responsive"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit["better_featured_image"]["media_details"]["sizes"]["content-lg"].source_url, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", (_v.parent.context.$implicit["better_featured_image"].description ? _v.parent.context.$implicit["better_featured_image"].description : "Bilde uten beskrivelse"), ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_HomeComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-archive", [], [[8, "className", 0]], null, null, i1.View_ArchiveComponent_0, i1.RenderType_ArchiveComponent)), i0.ɵdid(1, 245760, null, 0, i2.ArchiveComponent, [i3.ApiService], { catId: [0, "catId"], catSlug: [1, "catSlug"] }, null)], function (_ck, _v) { var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit["acf"]["kategori"][0]["term_id"], ""); var currVal_2 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.slug, ""); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
function View_HomeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "section", [["class", "section section__flip md__columns-auto margin__bottom color__gray"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "content__left module__text"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h1", [["class", "entry__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomeComponent_2)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomeComponent_3)), i0.ɵdid(9, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomeComponent_4)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "content__left module__text"; var currVal_1 = (_v.context.$implicit["better_featured_image"]["media_details"]["sizes"]["content-lg"].source_url ? "has__assets" : "container container__md"); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit["acf"].ingress; _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.$implicit["better_featured_image"]["media_details"]["sizes"]["content-lg"].source_url; _ck(_v, 9, 0, currVal_4); var currVal_5 = _v.context.$implicit["acf"]["kategori"]; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title.rendered; _ck(_v, 5, 0, currVal_2); }); }
export function View_HomeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i0.ɵdid(1, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.archive; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-home", [], [[8, "className", 0]], null, null, View_HomeComponent_0, RenderType_HomeComponent)), i0.ɵdid(1, 245760, null, 0, i5.HomeComponent, [i6.ActivatedRoute, i6.Router, i7.DataService, i3.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var HomeComponentNgFactory = i0.ɵccf("app-home", i5.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
