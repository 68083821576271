<section *ngFor="let module of modules; let i = index" id="{{ 'section_id-' + i }}" class="section" [ngClass]="
  [
    module['acf_fc_layout'] ? 'is-module is-'+module['acf_fc_layout'] : '',
    module['color'] ? 'has-background-'+module.color : '',
    module['margin'] ? 'has-margin'+module.margin : '',
    module['padding'] ? 'has-padding'+module.padding : ''
  ]">


  <div [ngSwitch]="module?.acf_fc_layout" [ngClass]="
  [
    module.column ? module.column : '',
    module.width ? 'container container__md is'+module.width : ''
  ]">
    <ng-template [ngSwitchCase]="'editor'">
      <h2 class="title is-3" *ngIf="module.title">{{module.title}}</h2>
      <div [innerHTML]="module.editor | safe:'html'" [ngClass]="['content']"></div>
    </ng-template>

  </div>
</section>
