<header class="module__header" *ngIf="data && data[0]">
  <h1 class="module__header-title">{{data[0].title.rendered}}</h1>
</header>

<section class="module margin__bottom container container__md" *ngIf="acf && acf.ingress">
  <div class="lead" [innerHTML]="acf.ingress | safe:'html'"></div>
</section>

<section class="section md__columns-auto margin__bottom" *ngFor="let block of blocks">
  <div class="content__left module__text" [ngClass]="(block.image) ? 'has__assets' : 'container container__md'">
    <div class="entry__content" [innerHTML]="block.editor" *ngIf="block.editor"></div>
  </div>
  <figure class="content__right module__background" *ngIf="block.image">
    <img class="img-responsive" src="{{block.image}}" alt="Bilde" />
  </figure>
</section>

<app-archive catId="{{acf['kategori'][0]['term_id']}}" catSlug="{{slug}}" *ngIf="data && data[0] && acf && acf.kategori"></app-archive>
