import { Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
const ɵ0 = () => import("./../page/page.module.ngfactory").then((m) => m.PageModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0
        // loadChildren: './../page/page.module#PageModule'
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: '**',
        redirectTo: '/not-found'
    }
];
export class CoreRoutingModule {
}
export { ɵ0 };
