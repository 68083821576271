<header class="module__header" *ngIf="data">
  <h1 class="module__header-title">{{data[0].title.rendered}}</h1>
</header>

<section class="module margin__bottom container container__md" *ngIf="data && data[0]['acf']['ingress']" >
  <div class="lead" [innerHTML]="data[0]['acf']['ingress'] | safe:'html'"></div>
</section>

<section class="section md__columns-auto margin__bottom" *ngFor="let block of blocks">
  <div class="module__text" [ngClass]="(block.image) ? 'has__assets' : 'container container__md'">
    <div class="entry__content" [innerHTML]="block.editor | safe:'html'" *ngIf="block.editor"></div>
  </div>
  <figure class="module__background" *ngIf="block.image">
    <img class="img-responsive" src="{{block.image}}" alt="Bilde" />
  </figure>
</section>
