<div class="container container__lg">

  <div class="content__info-meta meta">
    <div class="meta__item">
      <h1 class="meta__header h6">Besøksadresse</h1>
      <p>Gullhaugveien 1-3</p>
      <p>0484 Oslo - NO</p>
    </div>

    <div class="meta__item">
      <h1 class="meta__header h6">Postadresse</h1>
      <p>Postboks 4623 Nydalen</p>
      <p>0405 Oslo - NO</p>
    </div>

    <div class="meta__item">
      <h1 class="meta__header h6">Kontakt</h1>
      <p class="meta__item-block">
        <a class="btn btn__link" href="tel:22586000">22 58 60 00</a>
      </p>
      <p class="meta__item-block">
        <a class="btn btn__link" href="mailto:post@rvtsost.no">post@rvtsost.no</a>
      </p>
    </div>
  </div>

  <div class="content__info-disclaimer">
    <div class="legal">
      <h1 class="title h4">&copy; {{today | date:'yyyy'}} {{companyName}} - {{companyExtended}}</h1>
      <p>Vi respekterer og beskytter personvernet og personopplysningene dine. Les om bruk av informasjonskapsler
        (cookies), og om hvordan {{companyName}} samler inn og bruker <a class="btn btn__link"
          [routerLink]="['/personvern']" [routerLinkActive]="['active']">personopplysninger</a>.</p>

      <p><a class="btn btn__link" href="https://uustatus.no/nb/erklaringer/publisert/fa03e62d-5356-4940-95ee-508b31223ddd" target="_blank" rel="noreferrer">Tilgjengelighetserklæring</a></p>

    </div>

    <nav class="social__icons">
      <a href="https://facebook.com/rvtsost/" target="_blank">
        <span class="fab fa-facebook-square" aria-hidden="true"></span>
        <span class="sr-only">Facebook</span>
      </a>
    </nav>
  </div>
</div>
