<div class="hero__background" *ngIf="featuredImage" [lazyLoad]="featuredImage"></div>
<div class="hero__content" *ngIf="hero && displayTitle">
  <header class="hero__header">
    <div class="hero__inner">
      <h1 class="hero__title" *ngIf="hero.title">
        <span class="color__default">{{hero.title}}</span>
      </h1>
      <h2 class="hero__title-sub" *ngIf="hero.subtitle">
        <span class="color__default">{{hero.subtitle}}</span>
      </h2>
    </div>
  </header>
</div>



