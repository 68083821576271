/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../archive/archive.component.ngfactory";
import * as i3 from "../archive/archive.component";
import * as i4 from "../../../core/services/api.service";
import * as i5 from "../../pipes/safe.pipe";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./page.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../core/services/data.service";
var styles_PageComponent = [];
var RenderType_PageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageComponent, data: {} });
export { RenderType_PageComponent as RenderType_PageComponent };
function View_PageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "header", [["class", "module__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "module__header-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data[0].title.rendered; _ck(_v, 2, 0, currVal_0); }); }
function View_PageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "section", [["class", "module margin__bottom container container__md"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "lead"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.acf.ingress, "html")); _ck(_v, 1, 0, currVal_0); }); }
function View_PageComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "entry__content"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.editor; _ck(_v, 0, 0, currVal_0); }); }
function View_PageComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "figure", [["class", "content__right module__background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", "Bilde"], ["class", "img-responsive"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.image, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_PageComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "section", [["class", "section md__columns-auto margin__bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "content__left module__text"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageComponent_4)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageComponent_5)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "content__left module__text"; var currVal_1 = (_v.context.$implicit.image ? "has__assets" : "container container__md"); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.editor; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.image; _ck(_v, 7, 0, currVal_3); }, null); }
function View_PageComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-archive", [], [[8, "className", 0]], null, null, i2.View_ArchiveComponent_0, i2.RenderType_ArchiveComponent)), i0.ɵdid(1, 245760, null, 0, i3.ArchiveComponent, [i4.ApiService], { catId: [0, "catId"], catSlug: [1, "catSlug"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.acf["kategori"][0]["term_id"], ""); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.slug, ""); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
export function View_PageComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.SafePipe, [i6.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageComponent_3)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageComponent_6)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data && _co.data[0]); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.acf && _co.acf.ingress); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.blocks; _ck(_v, 6, 0, currVal_2); var currVal_3 = (((_co.data && _co.data[0]) && _co.acf) && _co.acf.kategori); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_PageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page", [], [[8, "className", 0]], null, null, View_PageComponent_0, RenderType_PageComponent)), i0.ɵdid(1, 245760, null, 0, i7.PageComponent, [i8.ActivatedRoute, i8.Router, i9.DataService, i4.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var PageComponentNgFactory = i0.ɵccf("app-page", i7.PageComponent, View_PageComponent_Host_0, {}, {}, []);
export { PageComponentNgFactory as PageComponentNgFactory };
