import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';

import { PageRoutingModule } from './page-routing.module';
import { SharedModule } from './../shared/shared.module';


import { HomeComponent } from './home/home.component';
import { LegalComponent } from './legal/legal.component';


@NgModule({
  declarations: [
    HomeComponent,
    LegalComponent
  ],
  imports: [
    CommonModule,
    PageRoutingModule,
    SharedModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    })
  ]
})
export class PageModule { }
