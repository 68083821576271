import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ApiService } from './../../core/services/api.service';
import { DataService } from './../../core/services/data.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  host: { '[class]': 'componentClass' }
})
export class HomeComponent implements OnInit, OnDestroy {
  paramSubscription: Subscription;

  componentClass: string;
  slug: string = 'forside';

  data: any;
  imageService: any;
  archive: any;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataService: DataService,
    private _apiService: ApiService,
    ) {}

  ngOnInit() {
    this.paramSubscription = this._apiService.getData('/pages', {
      params: {
        slug: this.slug
      }
    })
    .subscribe((res) => {
      if (res) {
        this._dataService.setData(res)
        this.componentClass = 'page page__' +res[0]['slug'];
        // this.data = res;

        this._apiService.getData('/pages', {
          params: {
            include: ['269,266']
          }
        })
        .subscribe((res) => {
          if (res) {
            this.archive = res;
          }
        })
      }
    })

  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
  }
}
