import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private root: string = environment.domain + environment.base + environment.wp;
  private options: any;

  constructor(
    private _http: HttpClient
  ) {}


  getData(data: string, options?: any): Observable<Object> {
    return this._http.get(this.getUrl(data), options? options : false)
  }

  getLegal(data: string, options?: any): Observable<Object> {
    return this._http.get(this.getLegalUrl(data), options? options : false)
  }

  postData(data: string, options?: any): Observable<Object> {
    return this._http.post(this.getUrl(data), options? options : false)
  }


  private getUrl(url: string): string {
    return this.root + url;
  }

  private getLegalUrl(url: string): string {
    return 'https://rvtsost.no' + '/api' + environment.wp + url;
  }

}
