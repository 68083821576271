import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApiService } from './../../../core/services/api.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  host: { '[class]': 'componentClass' }
})
export class ArchiveComponent implements OnInit, OnDestroy {
  @Input() public catId: string;
  @Input() public catSlug: string;
  Subscription: Subscription;

  componentClass: string = 'module__archive padding__bottom container md__columns md__columns-3 md__columns-spacing';

  data: any;
  slug: string;
  userDetail: [];
  userDetails: [];

  constructor(
    private _apiService: ApiService,
  ) {}

  ngOnInit() {
    this.Subscription = this._apiService.getData('/posts', {
      params: {
        categories: this.catId
      }
    })
    .subscribe((res) => {
      if (res && Object.keys(res).length > 0) {
        this.data = res;
      }
    })
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.Subscription.unsubscribe();
  }

}
