import { Component, LOCALE_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  host: { '[class]': 'componentClass' }
})
export class AppComponent {
  componentClass: string = 'root';
  constructor(
    @Inject(LOCALE_ID)
    protected localeId: string,
    private router: Router) {}

    toContent() {
      this.router.navigate([], {fragment: 'main'})
      console.log('scrolled?')
    }
}
