/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./archive.component";
import * as i4 from "../../../core/services/api.service";
var styles_ArchiveComponent = [];
var RenderType_ArchiveComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ArchiveComponent, data: {} });
export { RenderType_ArchiveComponent as RenderType_ArchiveComponent };
function View_ArchiveComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "article", [["class", "entry"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(3, 2), (_l()(), i0.ɵeld(4, 0, null, null, 1, "figure", [["class", "entry__image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "img", [["class", "img-responsive"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "header", [["class", "entry__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h2", [["class", "entry__header-title h4"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, (("/" + _co.catSlug) + "/"), _v.context.$implicit.slug); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit["better_featured_image"]["media_details"]["sizes"]["content-lg"].source_url, ""); var currVal_4 = i0.ɵinlineInterpolate(1, "", (_v.context.$implicit["better_featured_image"].description ? _v.context.$implicit["better_featured_image"].description : "Bilde"), ""); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.title.rendered; _ck(_v, 8, 0, currVal_5); }); }
export function View_ArchiveComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ArchiveComponent_1)), i0.ɵdid(1, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ArchiveComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-archive", [], [[8, "className", 0]], null, null, View_ArchiveComponent_0, RenderType_ArchiveComponent)), i0.ɵdid(1, 245760, null, 0, i3.ArchiveComponent, [i4.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var ArchiveComponentNgFactory = i0.ɵccf("app-archive", i3.ArchiveComponent, View_ArchiveComponent_Host_0, { catId: "catId", catSlug: "catSlug" }, {}, []);
export { ArchiveComponentNgFactory as ArchiveComponentNgFactory };
