import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LegalComponent } from './legal/legal.component';
import { PageComponent } from '../shared/components/page/page.component';
import { ArticleComponent } from '../shared/components/article/article.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'personvern',
    component: LegalComponent
  },
  {
    path: ':slug',
    component: PageComponent
  },
  {
    path: ':slug/:slug',
    component: ArticleComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PageRoutingModule { }
