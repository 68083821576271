import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ApiService } from './../../../core/services/api.service';
import { DataService } from './../../../core/services/data.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  host: { '[class]': 'componentClass' }
})
export class PageComponent implements OnInit, OnDestroy {
  paramSubscription: Subscription;
  componentClass: string;
  slug: string;

  data: any;
  acf: any = [];
  blocks: any = [];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataService: DataService,
    private _apiService: ApiService,
    ) {
      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit() {
    this.acf.length = 0;
    this.paramSubscription = this._route.paramMap.subscribe(
      params => {
        if (params.get('slug') != 'undefined') {
          this.slug = params.get('slug');
          this._apiService.getData('/pages', {
            params: {
              slug: this.slug
            }
          })
          .subscribe((res) => {
            if (res && Object.keys(res).length > 0)
            {
              this._dataService.setData(res)
              this.data = res;

              if ( this.blocks.length > 0 ) {
                this.blocks = [];
              }

              this.acf = []

              if ( res[0]['acf'] ) {
                this.acf = res[0]['acf'];

                if ( res[0]['acf']['module_builder'] ) {
                this.blocks = res[0]['acf']['module_builder'];
                this.componentClass = 'page page__' +res[0]['slug'];
                }
                else {
                  this.componentClass = 'archive archive__' +res[0]['slug'];
                }
              }
            }
            else {
              // this._router.navigate(['./not-found']);
              return 404;
            }
          })
        }
      }
    )
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
    this._dataService.clearData();
    this.acf.length = 0;

  }
}
