<section class="section section__flip md__columns-auto margin__bottom color__gray" *ngFor="let page of archive">

  <div class="content__left module__text"
    [ngClass]="(page['better_featured_image']['media_details']['sizes']['content-lg'].source_url) ? 'has__assets' : 'container container__md'">
    <h1 class="entry__title">{{page.title.rendered}}</h1>
    <div class="entry__content ingress" [innerHTML]="page['acf'].ingress" *ngIf="page['acf'].ingress"></div>
  </div>

  <figure class="content__right module__background" *ngIf="page['better_featured_image']['media_details']['sizes']['content-lg'].source_url">
    <img class="img-responsive" src="{{page['better_featured_image']['media_details']['sizes']['content-lg'].source_url}}"
      alt="{{page['better_featured_image'].description ? page['better_featured_image'].description : 'Bilde uten beskrivelse'}}" />
  </figure>

  <app-archive catId="{{page['acf']['kategori'][0]['term_id']}}" catSlug="{{page.slug}}" *ngIf="page['acf']['kategori']"></app-archive>
</section>
